<template>
    <div class="contacts dark:bg-black bg-white ">
        <div class="contacts-container ">
            <div class="contacts-section call-center flex">
                <div class="call-center__info">
                    <div class="call-center__info-content">
                        <div class="call-center__phones">
                            <h2>Call-center</h2>
                            <a class="text-7xl font-bold slide-right" href="tel:197">197</a>
                            <a class="slide-right sm:text-xl lg:text-sm" href="tel:+375339111197">+375 33 911 11 97</a>
                            <a class="slide-right sm:text-xl lg:text-sm" href="tel:+375172755788">+375 17 275 57 88</a>
                        </div>
                        <div class="call-center__work-time">
                            <p class="text-lg cursor-pointer p-5">Ежедневно: 9.00 – 21.00</p>
                        </div>
                    </div>
                </div>
                <div class="call-center__text">
                    <div class="call-center__text-wrapper">
                        <h1 class="lg:text-6xl sm:text-5xl   font-bold  mt-10 mb-24">
                            Контактная информация
                        </h1>
                        <p class="text-md mb-24">
                            Сообщаем, что получить консультацию и интересующую информацию об оказании услуг,
                            работе РУП
                            «Белтаможсервис», контактным данным узких специалистов, а также обратиться по вопросам сбоя
                            в
                            программном обеспечении, плохом обслуживании можно по единому номеру 197 (доступен по
                            территории
                            Республики Беларусь через операторов связи Белтелеком, МТС, A1, Life). Получить необходимую
                            информацию и консультацию о комплексе предлагаемых услуг и возможностях предприятия Вы
                            можете на
                            русском или английском языках.
                        </p>
                    </div>
                </div>
            </div>


            <div class="info-groups">
                <div class="info-groups__group">
                    <h4 class="info_groups__group-header">Юридический адрес:</h4>
                  222210, Минская область, Смолевичский район, Китайско-Белорусский индустриальный парк «Великий камень», проспект Пекинский, 18.
                </div>
                <div class="info-groups__group info-groups__group_bordered">
                    <h4 class="info_groups__group-header">
                        Адрес для корреспонденции:
                    </h4>
                    220036, г. Минск, улица Лермонтова, 27, каб. 501
                </div>
                <div class="info-groups__group">
                    <h4 class="info_groups__group-header">Время работы:</h4>
                    пн-пт: 8.30-17.30 <br>
                    обед: 13.00-14.00
                </div>
            </div>


            <div class="contacts-section departments">
                <div class="sm:w-full lg:w-1/2 ">
                    <div class="departments__info-wrapper">
                        <h1 class="lg:text-6xl sm:text-5xl font-bold text-white mb-10">
                            Информация по отделам
                        </h1>
                        <div class="">
                            <div v-for="(item, idx) in groups" :key="item.value+ idx" @click="selectGroup(item.value)"
                                 :class="{'custom-border-selected':item.value === selected}"
                                 class="custom-border p-3 text-white font-bold cursor-pointer"
                            >
                                {{ item.name }}
                            </div>

                        </div>
                    </div>
                </div>
                <div
                    class="sm:w-full lg:w-1/2 sm:my-20 lg:my-0  text-black dark:text-white flex justify-center items-center"
                >
                    <div class="p-12 border-t-2 border-l-2 border-b-2 border-black dark:border-gray-200">
                        <div v-if="phones[selected].length" class="contact-block mb-10">
                            <a :href="'tel:'+phone" v-for="(phone, idx) in phones[selected]" :key="'phone-'+idx"
                               class="contact-item"
                            >{{ phone }}</a>
                        </div>
                        <div v-if="Object.prototype.hasOwnProperty.call(mails, selected) && mails[selected].length"
                             class="contact-block">
                            <a :href="'mailto:'+mail" v-for="(mail, idx) in mails[selected]" :key="'mail-'+idx"
                               class="contact-item contact-item-mail"
                            >{{ mail }}</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


</template>

<script>
export default {
    name: "Contacts",
    meta: {
        title: 'Контакты'
    },
    data: () => ({
        selected: 'director',
        groups: [
            {name: 'Приёмная генерального директора', value: 'director'},
            {name: 'Отдел администрирования и контроля', value: 'control'},
            {name: 'Централизованная бухгалтерия', value: 'accounting'},
            {name: 'Главное управление развития и продаж', value: 'sales'},
            {name: 'Управление информационных технологий', value: 'soft'},
            {name: 'Коммерческое управление', value: 'commerce'},
            {name: 'Юридическое управление', value: 'law'},
            {name: 'Управление строительства и эксплуатации', value: 'bulding'},
            {name: 'Управление развития персонала', value: 'personal'},
            {name: 'Управление закупок', value: 'procurement'},
            {name: 'Телефон доверия для работников', value: 'helpline'},
            {name: 'PR управление', value: 'pr'},
        ],
        phones: {
            director: [
                '+375 17 259 75 03',
                '+375 17 259 75 04 (факс)'],
            control: [
                '+375 17 259 75 70',
                '+375 17 259 75 04 (факс)'],
            accounting: [
                '+375 17 259 75 11',
                '+375 17 259 75 12',
                '+375 17 259 75 13',
                '+375 17 259 75 14',
                '+375 17 259 75 15',
                '+375 17 259 75 19',
                '+375 17 259 75 18 (Мониторинг ТС)',
                '+375 17 259 75 10 (факс)'],
            sales: ['+375 17 259 75 58 ', '+375 17 259 75 87', '+375 17 388 60 88'],
            soft: ['+375 17 359 25 78'],
            commerce: ['+375 17 359 25 15', '+375 17 259 75 90',
                '+375 17 259 75 91 (факс)'],
            law: [
                '+375 17 259 75 22',
                '+375 17 259 75 25'],
            bulding: ['+375 17 259 75 34',
                '+375 17 259 75 77 (факс)'],
            personal: ['+375 17 259 75 42',
                '+375 17 259 75 43 (факс)'],
            procurement: ['+375 17 259 75 75',
                '+375 17 259 75 39'],
            helpline: ['8 (033) 333 58 48'],
            pr: ['+375 17 359 25 02'],
        },
        mails: {
            director: ['office@btslogistics.by'],
            control: ['delo@btslogistics.by'],
            sales: ['ul@btslogistics.by'],
            soft: ['uitis@btslogistics.by'],
            commerce: ['como@btslogistics.by'],

        }


    }),
    methods: {
        selectGroup(group) {
            this.selected = group
        }
    }

}
</script>

<style scoped>
.contacts-container {
    max-width: 1920px;
    margin: 0 auto 0 auto;
}

.call-center__info{
    @apply  sm:w-full lg:w-2/3 flex justify-center items-center dark:text-white text-black
}
.call-center__info-content{
    @apply flex sm:flex-col lg:flex-row p-12 border-t-2 border-r-2 border-b-2 border-black dark:border-gray-200 sm:w-full sm:mx-5 sm:my-12 lg:m-0 lg:w-2/3
}
.call-center__phones{
    @apply p-12 sm:w-full lg:w-1/2 flex flex-col  dark:text-white text-black
}
.call-center__work-time{
    @apply sm:w-full lg:w-1/2 sm:border-t-2  lg:border-t-0 lg:border-l-2 border-black dark:border-white flex justify-center text-center items-center
}
.call-center__text{
    @apply sm:w-full lg:w-1/3
}
.call-center__text-wrapper{
    @apply bg-blue-400 p-12 text-white h-full lg:rounded-l-md
}
.departments__info-wrapper{
    @apply bg-teal-500 sm:px-5 sm:py-12 lg:p-12  lg:rounded-r-md
}
.info-groups{
    @apply flex justify-center sm:flex-col lg:flex-row dark:text-white text-black border-t-2  border-b-2 dark:border-gray-100 border-black  sm:mx-5 sm:my-24 lg:m-24
}
.info-groups__group{
    @apply p-12 my-5 lg:w-1/3 sm:w-full
}
.info-groups__group_bordered{
    @apply lg:border-l-2 lg:border-r-2
    sm:border-r-0 sm:border-l-0 sm:border-t-2 sm:border-b-2 lg:border-t-0 lg:border-b-0
    dark:border-white border-black ;
}
.info_groups__group-header{
    @apply  text-xl mb-5
}

.slide-right {
    @apply transition-all transform duration-500 hover:translate-x-5
}

.contacts-section {
    @apply flex sm:flex-col lg:flex-row dark:bg-black bg-white py-12;
}
.custom-border {
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-bottom-width: 0;
}

.custom-border:last-child {
    border: 3px solid rgba(255, 255, 255, 0.3);
}

.custom-border-selected, .custom-border-selected:last-child {
    border: 3px solid rgba(255, 255, 255, 1) !important;
}

.custom-border-selected + .custom-border {
    border-top: 0;
}

.contact-block {
    @apply my-5;
}

.contact-item {
    @apply lg:text-3xl sm:text-xl dark:text-white  block transition ease-in-out transition-all duration-500 transform hover:translate-x-5
}

.contact-item-mail {
    @apply border-b-2 dark:border-white border-black pb-1
}
@media (min-width: 280px) {
    .contacts-section:first-child {
        flex-direction: column-reverse;
    }
}

@media (min-width: 1024px) {
    .contacts-section:first-child {
        flex-direction: row;
    }
}

@media (min-width: 1921px) {
    .call-center__text-wrapper {
        @apply rounded-md
    }
    .departments__info-wrapper{
        @apply rounded-md
    }
}

</style>